<template>
  <div>
    <authorization
      @submit="Login"
      v-model="timer"
      :error="error"
      :show-checking="showSMSchecking"
      @clearError="clearError()"
      @forgottenPassword="forgottenPassword"
      @verified="verified"
      @sendAgain="sendSms"
    />
  </div>
</template>

<script>
import Authorization from "@/components/pages/Authorization";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AuthorizationView",
  components: { Authorization },
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", [
      "isAUTHORIZED",
      "isCONFIRMED",
      "getSTATUS",
      "getLogin",
    ]),
    ...mapGetters("DocumentsModule", ["getNEED_UPDATE"]),
  },
  data() {
    return {
      loading: false,
      timer: false,
      error: false,
      showSMSchecking: false,
      user: {},
    };
  },
  methods: {
    ...mapActions("AuthModule", [
      "LOGIN",
      "SAVE_LOGIN",
      "CLEAR_LOGIN",
      "CHECK_LOGIN_PARE"
    ]),
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("DocumentsModule", ["GET_ALL_DOCUMENTS"]),
    clearError() {
      this.error = false;
    },
    forgottenPassword() {
      this.$router.push("/request-password");
    },
    sendSms() {
      this.CHECK_LOGIN_PARE(this.getLogin).then((response) => {
        if (response) {
          this.REQUEST_SMS({ phone: this.getLogin.email, action: 'login' }).then((success) => {
            if (success) {
              this.showSMSchecking = true;
              this.timer = true;
              this.loading = false;
            } else {
              this.$toast.error(`${this.getERROR}`);
            }
          });
        } else {
          this.$toast.error(`${this.getERROR}`);
        }
      });
    },
    Login(user) {
      this.loading = true;
      this.SAVE_LOGIN(user);
      this.sendSms();
    },
    async to(isConfirmed) {
      let link = "/complete-registration";
      if (isConfirmed) {
        await this.GET_ALL_DOCUMENTS({documentsListType: "updates"});
        if (this.getNEED_UPDATE != null && this.getNEED_UPDATE.length > 0)
          link = "/cabinet/documents";
        else
          link = "/cabinet/profile";
      }
      this.$router.push(link);
    },
    async verified(code) {
      this.loading = true;
      this.CONFIRM_SMS({ phone: this.getLogin.email, key: code }).then(
        (success) => {
          if (success) {
            this.LOGIN(this.getLogin).finally(() => {
              this.loading = false;
              if (this.isAUTHORIZED) {
                this.to(this.isCONFIRMED);
              } else {
                if (this.getERROR?.length > 0) {
                  this.$toast.error(`${this.getERROR}`);
                }
              }
            });
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
