<template>
  <authorization-wrapper>
    <div class="login-container__content-form">
      <div class="form-container">
        <h1>Вход в личный кабинет</h1>
        <form @submit.prevent="submit">
          <rwm-text-field
                  class="login"
                  v-model="form.login"
                  width="100%"
                  label="+7 ххх ххх хх хх или email@email.com"
                  :error="errors.login || error"
                  @blur="validate('login')"
                  @focus="$emit('clearError')"
          />
          <rwm-text-field
                  class="pass"
                  v-model="form.password"
                  width="100%"
                  label="Пароль"
                  :type="isOpenPass ? 'text' : 'password'"
                  :error="errors.password || error"
                  @blur="validate('password')"
                  @focus="$emit('clearError')"
          >
            <div slot="append-icon">
              <transition name="fade">
                <v-icon v-if="isOpenPass" @click="isOpenPass=!isOpenPass">view</v-icon>
                <v-icon v-else @click="isOpenPass=!isOpenPass">close</v-icon>
              </transition>
            </div>
          </rwm-text-field>
          <rwm-button width="100%" type="submit">Вход в личный кабинет</rwm-button>
        </form>
        <div class="forgot-pass">
          <rwm-underline-btn @click="forgottenPassword()">Забыли пароль?</rwm-underline-btn>
        </div>
        <rwm-sms-checking :isShow="showChecking" :time="60" v-model="_timer" @verified="codeIsVerified" @sendAgain="sendAgain()" />
        <div class="login-by-gov-service">
          <rwm-button outlined width="100%" @click="GoToESIA()">Войти через Госуслуги
            <template v-slot:prepend-img>
              <img src="@/assets/gususlugi_icon.png" alt="gususlugi_icon">
            </template>
          </rwm-button>
        </div>
        <div class="registration">
          <rwm-underline-btn to="/registration">Зарегистрироваться</rwm-underline-btn>
        </div>
      </div>
    </div>
  </authorization-wrapper>
</template>

<script>
import RwmTextField from "@/components/RWMTextField/RwmTextField";
import RwmButton from "@/components/RWMButton/RwmButton";
import RwmUnderlineBtn from "@/components/RWMUnderlineButton/RwmUnderlineBtn";
import AuthorizationWrapper from "@/components/modules/AuthorizationWrapper";
import RwmSmsChecking from "@/components/RWMSMSChecking/RwmSmsChecking";
import VIcon from "@/components/icons/VIcon";

export default {
  name: "Authorization",
  components: {VIcon, RwmSmsChecking, AuthorizationWrapper,RwmUnderlineBtn, RwmButton, RwmTextField},
  props: {
    timer: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    showChecking: {
      type: Boolean,
      required: true
    },
  },
  model: {
    prop: 'timer',
    event: 'timer',
  },
  computed: {
    _timer: {
      get() {
        return this.timer
      },
      set(val) {
        this.$emit('timer', val)
      }
    }
  },
  data() {
    return {
      errors: {
        login: false,
        password: false,
      },
      isOpenPass: false,
      form: {
        login: "",
        password: ""
      }
    }
  },
  methods: {
    submit() {
      if (this.isValid()) {
        this.isShow = true;
        let {login, password} = this.form
        if (login.substr(0, 1) === '+') login=login.replace(/\D/g, '');
        this.$emit('submit', {email: login, password: password})
      }
    },
    GoToESIA() {
      window.location.href = `${process.env.VUE_APP_API_URL}esia/oauth/login`
    },
    forgottenPassword() {
      this.$emit('forgottenPassword')
    },
    isValid() {
      let valid = false
      for (let form in this.form) {
        this.validate(form)
      }
      for (let err in this.errors) {
        if (this.errors[err]) {
          valid = true
        }
      }
      return !valid
    },
    codeIsVerified(code) {
      this.$emit('verified', code)
    },
    sendAgain() {
      this.$emit('sendAgain')
    },
    validate(param) {
      this.form[param].length === 0 ? this.errors[param] = true : this.errors[param] = false
      if (param.match('login')) {
        let mail = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,4}$/i
        let phone = /^\+7[\d() -]{4,14}\d$/
        mail.test(this.form[param]) || phone.test(this.form[param]) ? this.errors[param] = false : this.errors[param] = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.login-container__content-form {
      flex: 1 1 auto;
  padding: 0 42px;
  @media screen and (min-width: 961px){
    padding: 0;
  }
  & .form-container {
    max-width: 516px;
    margin: auto;
    padding-top: 50px;
    & h1 {
      margin-bottom: 32px;
      color: $RWM-dark;
    }
    & form {
      & .login,
      & .pass {
        margin-bottom: 32px;
      }
    }
    & .forgot-pass,
    & .sms-login,
    & .login-by-gov-service,
    & .registration {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
    }
</style>
